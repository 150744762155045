import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';

// app
import MenuView from './Menu.view';
import { collapseNav, expandNav, selectUserDepartment, selectUserDepartmentId } from 'stores';
import { SvgColor } from 'components';
import { useGetOperationalClients } from 'lib/admin';
import { useMedia } from 'hooks';
import * as utils from 'utils';
import config from 'config';

// mui
import ListAltIcon from '@material-ui/icons/ListAlt';
import ApartmentIcon from '@material-ui/icons/Apartment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BallotIcon from '@material-ui/icons/Ballot';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import HomeIcon from '@material-ui/icons/Home';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import PublicIcon from '@material-ui/icons/Public';
import SettingsIcon from '@material-ui/icons/Settings';
import TimelineIcon from '@material-ui/icons/Timeline';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import SyncAltIcon from '@material-ui/icons/SyncAlt';

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: '24px', height: '24px' }} />;

export default function Menu() {
  const dispatch = useDispatch();
  const history = useHistory();
  const media = useMedia();
  const configVars = useSelector((state) => get(state, 'config.vars'));
  const uiNavExpanded = useSelector((state) => get(state, 'ui.nav.expanded'));
  const user = useSelector((state) => state.user);
  const userIsBroker = utils.user.isBroker(user);
  const userIsProducer = utils.user.isProducer(user);
  const userIsUnderwriter = utils.user.isUnderwriter(user);
  const userIsClaimsTechnician = utils.user.isClaimsTechnician(user);
  const userHasAccessToQB = utils.user.hasAccessToQB(user);
  const isAdmin = useSelector((state) => get(state, 'user.isAdmin'));
  const userDepartment = useSelector(selectUserDepartment) || {};
  const userDepartmentId = useSelector(selectUserDepartmentId);
  const userDepartmentSlug = userDepartment && kebabCase(get(userDepartment, 'name', ''));
  const { data: parentList } = useGetOperationalClients({
    size: 1,
  });

  const locationParams = history.location.pathname.split('/');
  const locationFirstParam = locationParams.length > 0 && locationParams[1] ? locationParams[1] : null;
  const [firstLevel, setFirstLevel] = useState(locationFirstParam);

  const ppClaimsUrl = configVars.url.ppClaimsUrl;
  const edgeV2Url = configVars.url.edgeV2;

  useEffect(() => {
    return history.listen((location) => {
      const paramArr = location.pathname.split('/');
      const firstParam = paramArr.length > 0 && paramArr[1] ? paramArr[1] : null;

      setFirstLevel(firstParam);
    });
  }, [history]);

  const handleClick = (event) => {
    if (media.mobile) {
      dispatch(collapseNav());
    }
  };
  const toggleNav = () => {
    if (uiNavExpanded) {
      dispatch(collapseNav());
    } else {
      dispatch(expandNav());
    }
  };
  // abort
  if (!user || !user.id) return null;

  const departmentList = utils.user.department.getAll(user);
  const hasUserDepartmentsSet = utils.generic.isValidArray(departmentList, true);

  const menuItems = [
    {
      name: 'home',
      text: utils.string.t('app.home'),
      icon: HomeIcon,
      link: `${edgeV2Url}${config.routes.home.root}`,
      externalLink: true,
    },
    {
      name: 'department',
      text:
        (userIsBroker && utils.string.t('department.titlePlacements')) || (userIsProducer && utils.string.t('department.titleAccounts')),
      icon: ListAltIcon,
      link: `${config.routes.department.root}/${userDepartmentId}${userDepartmentSlug ? `/${userDepartmentSlug}` : ''}`,
      disabled: userIsUnderwriter || !hasUserDepartmentsSet || userIsClaimsTechnician,
    },
    {
      name: 'client',
      text: utils.string.t('client.title'),
      icon: ApartmentIcon,
      link: `${edgeV2Url}${config.routes.client.root}`,
      externalLink: true,
      disabled: !userIsBroker,
    },
    {
      name: 'market',
      text: utils.string.t('market.title'),
      icon: TimelineIcon,
      link: config.routes.market.root,
      isDev: true,
      disabled: userIsUnderwriter || userIsClaimsTechnician,
    },
    {
      name: 'products',
      text: utils.string.t('products.title'),
      icon: BallotIcon,
      link: config.routes.quoteBind.root,
      disabled: !userHasAccessToQB,
    },
    {
      name: 'binders',
      text: utils.string.t('binders.title'),
      icon: AllInboxIcon,
      link: `${edgeV2Url}${config.routes.binders.root}`,
      externalLink: true,
      disabled: userIsClaimsTechnician,
    },
    {
      name: 'claimsTaskManagement',
      text: utils.string.t('claimsTaskManagement.title'),
      icon: PlaylistAddCheckIcon,
      link: `${edgeV2Url}${config.routes.claimsTaskManagement.root}`,
      externalLink: true,
      disabled: !userIsClaimsTechnician,
    },
    {
      name: 'reportings',
      text: utils.string.t('reporting.title'),
      icon: icon('ic_analytics'),
      newIcon: true,
      new: true,
      link: `${edgeV2Url}${config.routes.reporting.root}`,
      externalLink: true,
    },
    {
      name: 'trips',
      text: utils.string.t('trips.title'),
      icon: FlightTakeoffIcon,
      link: config.routes.trip.root,
      disabled: !userIsBroker,
    },
    {
      name: 'opportunity',
      text: utils.string.t('opportunity.title'),
      icon: PublicIcon,
      link: config.routes.opportunity.root,
      disabled: !userIsBroker,
    },
    {
      name: 'modelling',
      text: utils.string.t('modelling.title'),
      icon: AssessmentIcon,
      link: config.routes.modelling.root,
      disabled: !userIsBroker,
    },
    {
      name: 'checklist',
      text: utils.string.t('openingMemo.title'),
      icon: PlaylistAddCheckIcon,
      link: config.routes.checklist.root,
      disabled: !userIsBroker,
      isDev: true,
    },
    {
      name: 'industryNews',
      text: utils.string.t('industryNews.title'),
      icon: AssignmentIcon,
      link: config.routes.industryNews.root,
      disabled: userIsUnderwriter,
      isDev: true,
    },
    {
      name: 'admin',
      text: utils.string.t('admin.title'),
      icon: SettingsIcon,
      link: config.routes.admin.root,
      adminOnly: true,
    },
    {
      name: 'ppClaimsUrl',
      text: utils.string.t('app.ppClaims'),
      icon: SyncAltIcon,
      link: ppClaimsUrl,
      ppClaim: true,
      externalLink: true,
      disabled: !userIsBroker && !userIsClaimsTechnician,
    },
  ];

  const menuItemsFiltered = menuItems
    .filter((item) => {
      const isActive = !item.disabled;
      const adminOnly = !item.adminOnly || isAdmin;
      const isDev = !item.isDev || utils.app.isDevelopment(configVars);
      const isValid = item.link || item.divider || item.title;

      return isActive && adminOnly && isDev && isValid;
    })
    .map((item) => {
      const isHome = item.name === 'home';
      item.selected = isHome ? firstLevel === null : item.link && item.link.includes(firstLevel);
      return item;
    });

  return <MenuView items={menuItemsFiltered} isExpanded={uiNavExpanded} handleClick={handleClick} toggleNav={toggleNav} />;
}
